<template>
  <div class="home">
    <Header class="Header"></Header>

    <div class="main">
      <div class="Title"></div>
      <div class="Standing"></div>
      <Form class="from"></Form>
    </div>
   <Footer></Footer>
  </div>
</template>

<script>
import Form from '@/components/pc/Input'
import Header from "@/components/pc/Header";
import Footer from "@/components/pc/Footer";

export default {
  components: {
    Header,
    Form,
    Footer
  },
  methods: {
    get_data(type, data) {
      this.$router.push({
        path: '/pc/search',
        query: {
          type: type,
          data: data,
          page: 0
        }
      });
    }
  }


}
</script>

<style lang="scss" scoped>
@mixin nav_block {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  left: 0;
  background-color: #f6f6f6;
}


.home {
  width: 100%;
  height: 100%;
  position: relative;

  .header {
    @include nav_block;
    top: 0;
    height: 50px;
    background: none;

  }

  @mixin main_bg {
    background-size: 100% 100%;
    position: absolute;
  }

  .main {
    position: absolute;
    width: 1200px;
    height: 500px;
    left: 50%;
    margin-left: -600px;
    top: 200px;

    .Title {
      @include main_bg;
      background-image: url("../../../assets/image/home/home.png");
      width:480px;
      height: 230px;
      top: -10px;
      left: 50%;
      margin-left: -240px;
    }

    .Standing {
      @include main_bg;
      background-image: url("../../../assets/image/home/Standing.png");
      width: 300px;
      height: 27px;
      top: 230px;
      left: 50%;
      margin-left: -150px;
    }

    .from {
      top: 290px;
      left: 50%;
      margin-left: -333px;
    }


  }


  .footer {
    @include nav_block;
    bottom: 0;
  }


}
</style>

